import { Button, Dropdown, IcFluentImmersiveReader24Regular, Tooltip, useGeneratedPrefixedId } from '@flipgrid/flipkit';
import classNames from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { invokeImmersiveReader } from './ImmersiveReaderHelperFunctions.client';

import type { themesForAllVariants, themesForNonTextVariants } from '@flipgrid/flipkit';

type Props = {
  className?: string;
  displayType?: 'default' | 'dropdown';
  text: string;
  theme?: themesForNonTextVariants | themesForAllVariants;
  title: string;
};

const ImmersiveReaderButton = ({ className, displayType = 'default', text, theme = 'secondary', title }: Props) => {
  const { t } = useTranslation();
  const [isFetching, setIsFetching] = useState(false);
  const titleAndText = title + (text ? '<br/>' + text : '');
  const buttonId = useGeneratedPrefixedId('immersive-reader-button');

  if (displayType === 'dropdown')
    return (
      <Dropdown.Item
        to=""
        data-testid="immersiveReaderButton__dropdownItem"
        icon={<IcFluentImmersiveReader24Regular />}
        onSelect={!isFetching ? () => invokeImmersiveReader(title, titleAndText, { setIsFetching }) : () => {}}
      >
        {t('shared.immersiveReader')}
      </Dropdown.Item>
    );

  return (
    <Tooltip label={t('shared.immersiveReader')}>
      <Button
        aria-label={t('immersiveReaderButton.launchImmersiveReader')}
        className={classNames('immersiveReaderButton', { '-isFetching': isFetching }, className)}
        data-testid="immersiveReaderButton__button__launch"
        icon={<IcFluentImmersiveReader24Regular />}
        id={buttonId}
        onClick={
          !isFetching
            ? () => invokeImmersiveReader(title, titleAndText, { setIsFetching, idToFocusOnExit: buttonId })
            : () => {}
        }
        size="36"
        theme={theme}
      />
    </Tooltip>
  );
};

export default ImmersiveReaderButton;
